import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import StudentHeader from "../header";
import Footer from "../../footer";
import GameService from '../../../Api/Services/GameService';
import ReactApexChart from "react-apexcharts";
import Loader from "../../Loader/Loader";
export default function StudentReport() {
    const [LevelID, setLevelID] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [LevelAvID, setLevelAvID] = useState();
    const [finalscoredata, setFinalScoredata] = useState([]);
    const [subjectData, setSubjectData] = useState([]);
    const [subjectID, setSubjectID] = useState("");


    const [average_scores, setaverage_scores] = useState([]);
    const [averagecategory, setaveragecategory] = useState([]);
    const [averagedata, setaveragedata] = useState([]);

    const navigate = useNavigate();
    const [Error, setError] = useState('');
    var imageUrl = process.env.REACT_APP_URL;

    useEffect(() => {
        if (localStorage.getItem('usertoken')) {


            SubjectList();
            // handleScoreList();


        } else {
            navigate('/login')
        }
    }, [navigate])


    const SubjectList = async () => {
        try {
            let responseData = await GameService.index().json();
            setSubjectData(responseData);
            var getclass = responseData.filter(function (item) {

                return item.id == 1;
            });
            setSubjectID(getclass[0].id)
            reportList(getclass[0].id)

        } catch (error) {
            console.log(error)
        }
    }




    const handlesubjectlist = (id) => {

        setSubjectID(id);



    }
    const reportList = async (id) => {


        try {
            if (id === '4') {
                setError("Data Not Found")
                setaverage_scores([])
                setaveragecategory([])
                setaveragedata([])
                setFinalScoredata([])
                setLevelID()
                setLevelAvID()
            }
            else if (id === 3) {
                let responseData = await GameService.escapeseechart(userdata).json()

                if (responseData.status === false) {

                    setError("Data Not Found")
                }
                else {
                    var array1 = responseData.levels.map(item => { return responseData.levels[0].level_id == item.level_id ? item.domains : [] })

                    setLevelID(responseData.levels[0].level_id)
                    setLevelAvID(responseData.levels[0].level_id)
                    setaverage_scores(responseData.levels)
                    setaverage_scores(responseData.levels)
                    const averagescores = [];
                    const averagesfinalcores = [];
                    const gamename = [];
                    // gamename.push("")
                    // averagescores.push("0")
                    // averagesfinalcores.push("0")
                    array1.map(item => { item.domain_name })

                    array1[0].map(item => {

                        averagescores.push(item.average_time[0])
                        averagesfinalcores.push(item.final_time[0])
                        gamename.push(item.domain_name)
                    })

                    setaveragecategory(gamename)
                    setaveragedata(averagescores)
                    setFinalScoredata(averagesfinalcores)

                    setIsLoading(false)
                }
            }
            else {
                setError("")
                var userdata = {
                    "game_id": id

                }

                let responseData = await GameService.scorechart(userdata).json()

                if (responseData.status === false) {

                    setError("Data Not Found")
                }
                else {
                    var array = responseData.levels.map(item => { return responseData.levels[0].level_id == item.level_id ? item.domains : [] })

                    setLevelID(responseData.levels[0].level_id)
                    setLevelAvID(responseData.levels[0].level_id)
                    setaverage_scores(responseData.levels)
                    setaverage_scores(responseData.levels)
                    const averagescores = [];
                    const averagesfinalcores = [];
                    const gamename = [];
                    // gamename.push("")
                    // averagescores.push("0")
                    // averagesfinalcores.push("0")
                    array.map(item => { item.domain_name })

                    array[0].map(item => {

                        averagescores.push(item.average_scores[0])
                        averagesfinalcores.push(item.final_scores[0])
                        gamename.push(item.domain_name)
                    })

                    setaveragecategory(gamename)
                    setaveragedata(averagescores)
                    setFinalScoredata(averagesfinalcores)

                    setIsLoading(false)
                }

            }


        } catch (error) {
            console.log(error)
        }
    }



    // const handleScoreList = async () => {
    //     try {
    //         var userdata = {
    //             "game_id": 1

    //         }
    //         let responseData = await GameService.scorechart(userdata).json()
    //         setLevelID(responseData.levels[0].level_id)
    //         setaverage_scores(responseData.levels)
    //         if (responseData.status === false) {
    //             setaverage_scores([])
    //         }
    //         else {
    //             var array = responseData.levels.map(item => { return responseData.levels[0].level_id == item.level_id ? item.domains : [] })

    //             setLevelID(responseData.levels[0].level_id)
    //             setaverage_scores(responseData.levels)
    //             setaverage_scores(responseData.levels)
    //             const averagescores = [];
    //             const gamename = [];
    //             gamename.push("")
    //             averagescores.push("0")
    //             array.map(item => { item.domain_name })

    //             array[0].map(item => {

    //                 averagescores.push(item.average_scores[0])
    //                 gamename.push(item.domain_name)
    //             })
    //             console.log(gamename)
    //             console.log(averagescores)
    //             setFinalScorecategory(gamename)
    //             setFinalScoredata(averagescores)
    //             setIsLoading(false)
    //         }


    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    const handleLevel = (id) => {


        const filteredProducts = average_scores.filter((item) =>
            id === item.level_id
        );

        var gamelist = filteredProducts.map((element) => { return element.domains })

        const averagescores = [];
        // const averagesfinalcores = [];

        const gamename = [];
        // gamename.push("")
        // averagescores.push("0")
        // averagesfinalcores.push("0")

        gamelist[0].map(item => {
            if (subjectID === 3) {
                averagescores.push(item.average_time[0])
            }
            else {
                averagescores.push(item.average_scores[0])
            }

            // averagesfinalcores.push(item.final_scores[0])
            gamename.push(item.domain_name)
        })
        setaveragecategory(gamename)
        setaveragedata(averagescores)

        // setFinalScoredata(averagesfinalcores)
        setIsLoading(false)
    }

    const handlefinalLevel = (id) => {
        console.log(subjectID, "finalscore")

        const filteredProducts = average_scores.filter((item) =>
            id === item.level_id
        );

        var gamelist = filteredProducts.map((element) => { return element.domains })


        const averagesfinalcores = [];

        const gamename = [];
        // gamename.push("")

        // averagesfinalcores.push("0")

        gamelist[0].map(item => {

            if (subjectID === 3) {
                averagesfinalcores.push(item.final_time[0])
            }
            else {
                averagesfinalcores.push(item.final_scores[0])
            }

            gamename.push(item.domain_name)
        })
        setaveragecategory(gamename)


        setFinalScoredata(averagesfinalcores)
        setIsLoading(false)
    }




    return (
        <div className="main-wrapper">
            <StudentHeader activeMenu={"Dashboard"} />
            <div className="page-content instructor-page-content pt-4 mt-1">
                <div className="container">
                    <div className="row">
                        {/* Sidebar */}

                        {/* Sidebar */}

                        {/* Instructor Dashboard */}
                        <div className="col-xl-12 col-lg-12 col-md-12 p-0">
                            <div className="row">
                                <div className="common-full-content">
                                    <div className='filter-tabs mt-2'>
                                        <ul>

                                            {subjectData.map((data) => (
                                                <li key={data.id}>

                                                    {data.name === "Nulmeting" || data.name === "ontSNAP de wiskunde" ? ""

                                                        : <Link className={subjectID == data.id ? "active" : "inactive"} to="" onClick={() => {
                                                            handlesubjectlist(data.id)
                                                            reportList(data.id)
                                                        }
                                                        }>
                                                            <img className="img-drop-area" height={data.icon ? 15 : 0} width={data.icon ? 15 : 0} src={imageUrl + "/Subject/" + data.icon} alt='' />
                                                            &nbsp;
                                                            {data.name}
                                                        </Link>}

                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className= {subjectID === 2 ? 'row mt-4 pt-3 taalkbus-tab' :'row mt-4 pt-3'}>
                                        {isLoading ? <div className='loader-overlay'><Loader /></div> :
                                            <>
                                                <div className='col-lg-6' >
                                                    <div key={"scorelist"} >
                                                        <div className='student-box flex-fill bg-white avarage-chart'>
                                                            <div className='student-box-top d-flex justify-content-between align-items-center'>
                                                                <h5>Gemiddelde score per domein</h5>

                                                            </div >
                                                            <div className='student-box-middle m-0'>
                                                                <div className='filter-tabs mt-2 filter-tab-spacing'>
                                                                    <ul>

                                                                        {average_scores.map((data) => (
                                                                            <>
                                                                                <li key={data.level_id} onClick={() => {
                                                                                    setLevelID(data.level_id),
                                                                                        handleLevel(data.level_id)
                                                                                }

                                                                                }>
                                                                                    <Link className={LevelID == data.level_id ? "active" : "inactive"}>

                                                                                        {data.level_title ? data.level_title : data.level_name}
                                                                                    </Link>
                                                                                </li>
                                                                            </>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                                {
                                                                    Error ? <div className='student-box-top  justify-content-between align-items-center ' style={{ marginTop: '20px' }}>
                                                                        <p className='mb-0'>{Error}</p>

                                                                    </div>
                                                                        :
                                                                        <>
                                                                            {isLoading ? (
                                                                                <div
                                                                                    style={{
                                                                                        width: "100px",
                                                                                        margin: "auto",
                                                                                    }}
                                                                                >
                                                                                    <p>Please wait</p>
                                                                                </div>
                                                                            ) : (
                                                                                // <ReactApexChart options={{
                                                                                //     chart: {
                                                                                //         height: 500,
                                                                                //         type: "area",
                                                                                //         toolbar: {
                                                                                //             show: false,
                                                                                //         },
                                                                                //         zoom: {
                                                                                //             enabled: false
                                                                                //         },
                                                                                //     },
                                                                                //     dataLabels: {
                                                                                //         enabled: false,
                                                                                //     },
                                                                                //     stroke: {
                                                                                //         curve: "smooth",
                                                                                //         width: 3,
                                                                                //     },
                                                                                //     colors: ["#81B5E9"],
                                                                                //     fill: {
                                                                                //         gradient: {
                                                                                //             opacityFrom: 0.6,
                                                                                //             opacityTo: 0.5,
                                                                                //         },
                                                                                //     },
                                                                                //     xaxis: {
                                                                                //         categories: category,
                                                                                //         // labels: {
                                                                                //         //     style: {
                                                                                //         //         colors: [],
                                                                                //         //         fontSize: '12px',
                                                                                //         //         fontFamily: 'Helvetica, Arial, sans-serif',
                                                                                //         //         fontWeight: 200,
                                                                                //         //         cssClass: 'apexcharts-xaxis-label',
                                                                                //         //     },
                                                                                //         //   }
                                                                                //     },

                                                                                //     title: {
                                                                                //         text: undefined,
                                                                                //         offsetX: 0,
                                                                                //         offsetY: 0,
                                                                                //         style: {
                                                                                //             color: undefined,
                                                                                //             fontSize: "12px",
                                                                                //             fontFamily: "Helvetica, Arial, sans-serif",
                                                                                //             fontWeight: 600,
                                                                                //             cssClass: "apexcharts-xaxis-title",
                                                                                //         },
                                                                                //     },
                                                                                //     yaxis: {
                                                                                //         // seriesName: "seriesY",
                                                                                //         logbase: 10,
                                                                                //         // logarithmic: true,
                                                                                //         tickAmount: 5,
                                                                                //         axisBorder: {
                                                                                //             show: true,
                                                                                //             color: "#E5E4E2",
                                                                                //             offsetX: 0,
                                                                                //             offsetY: 0,
                                                                                //         },
                                                                                //     },
                                                                                //     grid: {
                                                                                //         show: true, // you can either change hear to disable all grids
                                                                                //         xaxis: {
                                                                                //             lines: {
                                                                                //                 show: false, //or just here to disable only x axis grids
                                                                                //             },

                                                                                //         },
                                                                                //         yaxis: {
                                                                                //             lines: {
                                                                                //                 show: true, //or just here to disable only y axis
                                                                                //             },

                                                                                //         },
                                                                                //     },
                                                                                //     markers: {
                                                                                //         size: 3,
                                                                                //         colors: undefined,
                                                                                //         strokeColors: "#fff",
                                                                                //         strokeWidth: 2,
                                                                                //         strokeOpacity: 0.9,
                                                                                //         strokeDashArray: 0,
                                                                                //         fillOpacity: 1,
                                                                                //         discrete: [],
                                                                                //         shape: "circle",
                                                                                //         radius: 2,
                                                                                //         offsetX: 0,
                                                                                //         offsetY: 0,
                                                                                //         onClick: undefined,
                                                                                //         onDblClick: undefined,
                                                                                //         showNullDataPoints: true,
                                                                                //         hover: {
                                                                                //             size: 8,
                                                                                //             sizeOffset: 3,
                                                                                //         },
                                                                                //     },
                                                                                //     tooltip: {
                                                                                //         x: {
                                                                                //             // format: "dd/MM/yy HH:mm",
                                                                                //         },
                                                                                //     },
                                                                                // }}
                                                                                //     series={[{
                                                                                //         name: 'series-1',
                                                                                //         data: data
                                                                                //     }]} type="line" height={500} />
                                                                               
                                                                                <ReactApexChart options={{

                                                                                    // chart: {
                                                                                    //     height: 500,
                                                                                    //     type: "area",
                                                                                    //     toolbar: {
                                                                                    //         show: false,
                                                                                    //     },
                                                                                    //     zoom: {
                                                                                    //         enabled: false
                                                                                    //     },
                                                                                    // },
                                                                                    // dataLabels: {
                                                                                    //     enabled: false,
                                                                                    // },
                                                                                    // stroke: {
                                                                                    //     curve: 'straight',
                                                                                    //     width: 2,
                                                                                    //     show: true,
                                                                                    //     opacity: 0.0
                                                                                    // },
                                                                                    // colors: ["#81B5E9"],
                                                                                    // fill: {
                                                                                    //     gradient: {
                                                                                    //         shadeIntensity: 1,
                                                                                    //         opacityFrom: 0.6,
                                                                                    //         opacityTo: 0.5,
                                                                                    //     },
                                                                                    // },
                                                                                    // xaxis: {
                                                                                    //     categories: averagecategory,
                                                                                    //     axisBorder: {
                                                                                    //         show: true, // Hides the bottom line (x-axis border)
                                                                                    //       },
                                                                                    //       axisTicks: { show: false }, 
                                                                                    //       labels: {
                                                                                    //         offsetX: 0, // Adjust this value to move the labels closer to the x-axis
                                                                                    //     },
                                                                                    //     range: averagecategory.length - 0.56, 
                                                                                    //     max: averagecategory.length - (-0.23), // Add padding to the right

                                                                                    // },

                                                                                    // title: {
                                                                                    //     text: undefined,
                                                                                    //     offsetX: 0,
                                                                                    //     offsetY: 0,
                                                                                        
                                                                                    //     style: {
                                                                                    //         color: undefined,
                                                                                    //         fontSize: "18px",
                                                                                    //         fontFamily: "Helvetica, Arial, sans-serif",
                                                                                    //         fontWeight: 600,
                                                                                    //         cssClass: "apexcharts-xaxis-title",
                                                                                    //     },
                                                                                    // },
                                                                                    // yaxis: {
                                                                                    //     // forceNiceScale: false,
                                                                                    //     tickAmount: 5,
                                                                                    //     max: 10,
                                                                                    //     min: 0,
                                                                                    //     labels: {
                                                                                    //         formatter: (val) => (val),
                                                                                    //         // formatter: (value) => value.toFixed(0),
                                                                                    //     },
                                                                                    //     axisBorder: {
                                                                                    //         show: false,
                                                                                    //         color: "#E5E4E2",
                                                                                    //         offsetX: 0,
                                                                                    //         offsetY: 0,
                                                                                    //     },

                                                                                    // },
                                                                                    // grid: {

                                                                                    //     show: true, // you can either change hear to disable all grids
                                                                                    //     xaxis: {
                                                                                    //         lines: {
                                                                                    //             show: false, //or just here to disable only x axis grids
                                                                                    //         },

                                                                                    //     },
                                                                                    //     yaxis: {
                                                                                    //         lines: {
                                                                                    //             show: true, //or just here to disable only y axis
                                                                                    //         },

                                                                                    //     },
                                                                                    // },
                                                                                    // markers: {
                                                                                    //     size: 5,
                                                                                    //     colors: undefined,
                                                                                    //     strokeColors: "#fff",
                                                                                    //     strokeWidth:0,
                                                                                    //     strokeOpacity: 0.9,
                                                                                    //     strokeDashArray: 0,
                                                                                    //     fillOpacity: 1,
                                                                                    //     discrete: [],
                                                                                    //     shape: "circle",
                                                                                    //     radius: 2,
                                                                                    //     offsetX: 0,
                                                                                    //     offsetY: 0,
                                                                                    //     onClick: undefined,
                                                                                    //     onDblClick: undefined,
                                                                                    //     showNullDataPoints: true,
                                                                                    //     hover: {
                                                                                    //         size: 8,
                                                                                    //         sizeOffset: 3,
                                                                                    //     },
                                                                                    // },
                                                                                    // tooltip: {
                                                                                    //     x: {
                                                                                    //         // format: "dd/MM/yy HH:mm",
                                                                                    //     },
                                                                                    // },
                                                                                    chart: {
                                                                                        height: 500,
                                                                                        type: "area",
                                                                                        toolbar: {
                                                                                            show: false,
                                                                                        },
                                                                                        zoom: {
                                                                                            enabled: false
                                                                                        },
                                                                                    },
                                                                                    
                                                                                    dataLabels: {
                                                                                        enabled: false,
                                                                                    },
                                                                                    stroke: {
                                                                                        curve: 'straight',
                                                                                        width: 2,
                                                                                        show: true,
                                                                                    },
                                                                                    colors: ["#81B5E9"],
                                                                                    fill: {
                                                                                        gradient: {
                                                                                            opacityFrom: 0.6,
                                                                                            opacityTo: 0.5,
                                                                                        },
                                                                                    },
                                                                                    xaxis: {
                                                                                        tickAmount: 6,
                                                                                        categories: averagecategory,
                                                                                        // labels: {
                                                                                        //     style: {
                                                                                        //         colors: [],
                                                                                        //         fontSize: '12px',
                                                                                        //         fontFamily: 'Helvetica, Arial, sans-serif',
                                                                                        //         fontWeight: 200,
                                                                                        //         cssClass: 'apexcharts-xaxis-label',
                                                                                        //     },
                                                                                        //   }
                                                                                        
                                                                                        axisTicks: { show: false }, 
                                                                                        labels: {
                                                                                            offsetX: 0, // Adjust this value to move the labels closer to the x-axis line
                                                                                          },
                                                                                          range: averagecategory.length - 0.56, 
                                                                                        max: averagecategory.length - (-0.23), // Add padding to the right
                                                                                          
                                                                                    },

                                                                                    title: {
                                                                                        text: undefined,
                                                                                        offsetX: 0,
                                                                                        offsetY: 0,
                                                                                        style: {
                                                                                            color: undefined,
                                                                                            fontSize: "12px",
                                                                                            fontFamily: "Helvetica, Arial, sans-serif",
                                                                                            fontWeight: 600,
                                                                                            cssClass: "apexcharts-xaxis-title",
                                                                                        },
                                                                                    },
                                                                                    yaxis: {
                                                                                        // forceNiceScale: false,
                                                                                        tickAmount: 5,
                                                                                        logbase: 10,
                                                                                        max: 10,
                                                                                        min: 0,
                                                                                        labels: {
                                                                                            formatter: (val) => (val),
                                                                                            // formatter: (value) => value.toFixed(0),
                                                                                        },
                                                                                        axisBorder: {
                                                                                            show: false,
                                                                                            color: "#E5E4E2",
                                                                                            offsetX: 0,
                                                                                            offsetY: 0,
                                                                                        },
                                                                                    },
                                                                                    // yaxis: {
                                                                                    //     // seriesName: "seriesY",
                                                                                    //     logbase: 10,
                                                                                    //     // logarithmic: true,
                                                                                    //     tickAmount: 5,
                                                                                    //     axisBorder: {
                                                                                    //         show: true,
                                                                                    //         color: "#E5E4E2",
                                                                                    //         offsetX: 0,
                                                                                    //         offsetY: 0,
                                                                                    //     },
                                                                                    // },
                                                                                    grid: {
                                                                                        show: true, // you can either change hear to disable all grids
                                                                                        padding: {
                                                                                            left: 25,
                                                                                          },
                                                                                        xaxis: {
                                                                                            lines: {
                                                                                                show: false, //or just here to disable only x axis grids
                                                                                            },

                                                                                        },
                                                                                        yaxis: {
                                                                                            lines: {
                                                                                                show: true, //or just here to disable only y axis
                                                                                            },

                                                                                        },
                                                                                    },
                                                                                    markers: {
                                                                                        size: 5,
                                                                                        colors: undefined,
                                                                                        strokeColors: "#fff",
                                                                                        strokeWidth: 0,
                                                                                        strokeOpacity: 0.9,
                                                                                        strokeDashArray: 0,
                                                                                        fillOpacity: 1,
                                                                                        discrete: [],
                                                                                        shape: "circle",
                                                                                        radius: 2,
                                                                                        offsetX: 0,
                                                                                        offsetY: 0,
                                                                                        onClick: undefined,
                                                                                        onDblClick: undefined,
                                                                                        showNullDataPoints: true,
                                                                                        hover: {
                                                                                            size: 8,
                                                                                            sizeOffset: 3,
                                                                                        },
                                                                                    },
                                                                                    tooltip: {
                                                                                        x: {
                                                                                            // format: "dd/MM/yy HH:mm",
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                    series={[{
                                                                                        name: 'series-1',
                                                                                        data: averagedata
                                                                                    }]} type="line" height={370} />
                                                                            )}
                                                                        </>

                                                                }

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className='col-lg-6'>
                                                    <div key={"scorelist"} >
                                                        <div className='student-box flex-fill bg-white avarage-chart'>
                                                            <div className='student-box-top d-flex justify-content-between align-items-center'>
                                                                <h5>Score eindtoets per domein</h5>

                                                            </div >
                                                            <div className='student-box-middle m-0'>
                                                                <div className='filter-tabs mt-2 filter-tab-spacing'>
                                                                    <ul>

                                                                        {average_scores.map((data) => (
                                                                            <>
                                                                                <li key={data.level_id} onClick={() => {
                                                                                    setLevelAvID(data.level_id),
                                                                                        handlefinalLevel(data.level_id)
                                                                                }

                                                                                }>
                                                                                    <Link className={LevelAvID == data.level_id ? "active" : "inactive"}>

                                                                                        {data.level_title ? data.level_title : data.level_name}
                                                                                    </Link>
                                                                                </li>
                                                                            </>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                                {
                                                                    Error ?
                                                                        <div className='student-box-top  justify-content-between align-items-center ' style={{ marginTop: '20px' }}>
                                                                            <p className='mb-0'>{Error}</p>

                                                                        </div>

                                                                        :
                                                                        <>
                                                                            {isLoading ? (
                                                                                <div
                                                                                    style={{
                                                                                        width: "100px",
                                                                                        margin: "auto",
                                                                                    }}
                                                                                >
                                                                                    <p>Please wait</p>
                                                                                </div>
                                                                            ) : (
                                                                                <ReactApexChart options={{
                                                                                    chart: {
                                                                                        height: 500,
                                                                                        type: "area",
                                                                                        toolbar: {
                                                                                            show: false,
                                                                                        },
                                                                                        zoom: {
                                                                                            enabled: false
                                                                                        },
                                                                                    },
                                                                                    
                                                                                    dataLabels: {
                                                                                        enabled: false,
                                                                                    },
                                                                                    stroke: {
                                                                                        curve: 'straight',
                                                                                        width: 2,
                                                                                        show: true,
                                                                                    },
                                                                                    colors: ["#CDA7FF"],
                                                                                    fill: {
                                                                                        gradient: {
                                                                                            opacityFrom: 0.6,
                                                                                            opacityTo: 0.5,
                                                                                        },
                                                                                    },
                                                                                    xaxis: {
                                                                                        tickAmount: 6,
                                                                                        categories: averagecategory,
                                                                                        // labels: {
                                                                                        //     style: {
                                                                                        //         colors: [],
                                                                                        //         fontSize: '12px',
                                                                                        //         fontFamily: 'Helvetica, Arial, sans-serif',
                                                                                        //         fontWeight: 200,
                                                                                        //         cssClass: 'apexcharts-xaxis-label',
                                                                                        //     },
                                                                                        //   }
                                                                                        
                                                                                        axisTicks: { show: false }, 
                                                                                        labels: {
                                                                                            offsetX: 0, // Adjust this value to move the labels closer to the x-axis line
                                                                                          },
                                                                                          range: averagecategory.length - 0.56, 
                                                                                        max: averagecategory.length - (-0.23), // Add padding to the right
                                                                                          
                                                                                    },

                                                                                    title: {
                                                                                        text: undefined,
                                                                                        offsetX: 0,
                                                                                        offsetY: 0,
                                                                                        style: {
                                                                                            color: undefined,
                                                                                            fontSize: "12px",
                                                                                            fontFamily: "Helvetica, Arial, sans-serif",
                                                                                            fontWeight: 600,
                                                                                            cssClass: "apexcharts-xaxis-title",
                                                                                        },
                                                                                    },
                                                                                    yaxis: {
                                                                                        // forceNiceScale: false,
                                                                                        tickAmount: 5,
                                                                                        logbase: 10,
                                                                                        max: 10,
                                                                                        min: 0,
                                                                                        labels: {
                                                                                            formatter: (val) => (val),
                                                                                            // formatter: (value) => value.toFixed(0),
                                                                                        },
                                                                                        axisBorder: {
                                                                                            show: false,
                                                                                            color: "#E5E4E2",
                                                                                            offsetX: 0,
                                                                                            offsetY: 0,
                                                                                        },
                                                                                    },
                                                                                    // yaxis: {
                                                                                    //     // seriesName: "seriesY",
                                                                                    //     logbase: 10,
                                                                                    //     // logarithmic: true,
                                                                                    //     tickAmount: 5,
                                                                                    //     axisBorder: {
                                                                                    //         show: true,
                                                                                    //         color: "#E5E4E2",
                                                                                    //         offsetX: 0,
                                                                                    //         offsetY: 0,
                                                                                    //     },
                                                                                    // },
                                                                                    grid: {
                                                                                        show: true, // you can either change hear to disable all grids
                                                                                        padding: {
                                                                                            left: 25,
                                                                                          },
                                                                                        xaxis: {
                                                                                            lines: {
                                                                                                show: false, //or just here to disable only x axis grids
                                                                                            },

                                                                                        },
                                                                                        yaxis: {
                                                                                            lines: {
                                                                                                show: true, //or just here to disable only y axis
                                                                                            },

                                                                                        },
                                                                                    },
                                                                                    markers: {
                                                                                        size: 5,
                                                                                        colors: undefined,
                                                                                        strokeColors: "#fff",
                                                                                        strokeWidth: 0,
                                                                                        strokeOpacity: 0.9,
                                                                                        strokeDashArray: 0,
                                                                                        fillOpacity: 1,
                                                                                        discrete: [],
                                                                                        shape: "circle",
                                                                                        radius: 2,
                                                                                        offsetX: 0,
                                                                                        offsetY: 0,
                                                                                        onClick: undefined,
                                                                                        onDblClick: undefined,
                                                                                        showNullDataPoints: true,
                                                                                        hover: {
                                                                                            size: 8,
                                                                                            sizeOffset: 3,
                                                                                        },
                                                                                    },
                                                                                    tooltip: {
                                                                                        x: {
                                                                                            // format: "dd/MM/yy HH:mm",
                                                                                        },
                                                                                    },
                                                                                }}
                                                                                    series={[{
                                                                                        name: 'series-1',
                                                                                        data: finalscoredata
                                                                                    }]} type="line" height={370} />
                                                                            )}
                                                                        </>

                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>


                            </div>
                        </div>
                        {/* Instructor Dashboard */}
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    );
}
