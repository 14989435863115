import React, { useState, useEffect } from 'react';
import { InstructorHeader } from "../header";
import Footer from "../../footer";

import { Link, useNavigate } from "react-router-dom";
import InstructorSidebar from "../sidebar";
import SidebarTop from "../sidebartop";
import Loader from "../../Loader/Loader";

import TeacherService from '../../../Api/Services/TeacherService';
import Placeholder from '../../../assets/img/placeholder-image.png';
import { Modal, Button } from 'react-bootstrap';
export default function InstructorStudentGrid() {
  const [file, setFile] = useState()
  const [Error, setError] = useState("")
  var imageUrl = process.env.REACT_APP_URL;
  const [Success, setSuccess] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [loader, setLoader] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [totalData, setTotalData] = useState('');
  const [classdata, setClassdata] = useState([]);
  // const [level, setLevel] = useState([]);
  const [classID, setClassID] = useState('');
  const [classroomId, setClassroomid] = useState('');
  const [searchName, setSearchName] = useState('');
  // const [levelID, setLevelID] = useState('');
  // const [continuityScore, setContinuityScore] = useState('');
  const [noRecord, setNoRecord] = useState(true);
  const navigate = useNavigate();
  const [eye1, seteye1] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPage = 100;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = studentData.slice(firstIndex, lastIndex);

  const npage = Math.ceil(studentData.length / recordsPage)
  const number = [...Array(npage + 1).keys()].slice(1)
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1)
    }
  }
  function toggle() {
    setIsOpen((isOpen) => !isOpen);
    setFile("");
    setError("");
  }
  useEffect(() => {
    if (localStorage.getItem('usertoken')) {



      handlestudentlist();
      handleclasslist();
      handleTeacherProfile();


    } else {
      navigate('/login')
    }
  }, [navigate])

  const handleclasslist = async () => {
    try {

      let responseData = await TeacherService.class_index().json();

      if (responseData.total !== 0) {

        console.log(responseData)
        // setClassdata(responseData.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleTeacherProfile = async () => {
    try {

      let responseData = await TeacherService.teacher_profile().json()

      if (responseData.status === true) {
        console.log(responseData, "name")
        setClassdata(responseData.classroom)
        // setProfiledata(responseData)



      }

    } catch (error) {
      console.log(error)
    }

  }
  // const handlelevel = async () => {
  //   try {
  //     let responseData = await TeacherService.current_level_index().json();
  //     if (responseData.total > 0) {
  //       setLevel(responseData.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }

  // }

  const handlestudentlist = async (id, name) => {
    console.log(id, "id")

    setClassID(id);
    setSearchName(name);
    setNoRecord(true);
    try {
      var userData = {
        "classname": id == undefined ? "" : ((name == undefined || name == "") ? id : id),
        "searchname": name == undefined ? "" : name,
      }
      let responseData = await TeacherService.student_index(userData).json();

      setLoader(false);
      setTotalData(responseData.total);
      setNoRecord(false);
      if (responseData.total != 0) {
        setStudentData(responseData.data);
        setNoRecord(false);
        setLoader(false);
      }

    } catch (error) {
      console.log(error)
    }

  }
  const onEyeClick1 = () => {
    seteye1(!eye1)
  }
  const onFileChange = (e, file) => {
    if (file) {
      setFile("");
      return;
    }
    const fileReader = new FileReader()
    fileReader.readAsDataURL(e.target.files[0])
    fileReader.onload = () => {
      var icon = fileReader.result
      setFile(icon)
    }
  }
  const addStudent = async (event) => {
    event.preventDefault();
    var roledata = JSON.parse(localStorage.getItem("user"));

    try {
      var userData = {
        "firstname": event.target.firstname.value,
        "lastname": event.target.lastname.value,
        "email": event.target.email.value,
        "password": event.target.password.value,
        "role_id": 3,
        "school_id": roledata.school_id,
        "teacher_id": roledata.id,
        "image": file ? file.split(',')[1] : "",
        "classroom": (classroomId == undefined || classroomId == "" || classroomId == null) ? "" : classroomId.toString(),
        // "currentlevel": (levelID == undefined || levelID == "" || levelID == null) ? "" : JSON.parse(levelID),
        // "continuityscore": (continuityScore == undefined || continuityScore == "" || continuityScore == null) ? "" : JSON.parse(continuityScore),
      }
      if (userData.firstname !== "" && userData.lastname !== "" && userData.email !== "" && userData.password !== "" && userData.image != "") {
        if (classroomId == undefined || classroomId == "" || classroomId == null) {
          setError("The class field is required");
          return;
        }
        // if (levelID == undefined || levelID == "" || levelID == null) {
        //   setError("The level field is required");
        //   return;
        // }
        // if (continuityScore == undefined || continuityScore == "" || continuityScore == null) {
        //   setError("The continuityscore field is required");
        //   return;
        // }
      }
      let responseData = await TeacherService.student_add(userData).json()
      if (responseData.status === true) {
        setClassroomid("")
        setIsOpen(false);
        setSuccess(true);
        setModalMessage("Student create successfully...");
        setError("");
        setClassroomid("")
        // navigate("/admin/driving");
      }
    } catch (error) {

      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")));
      }
    }

  };

  // const exceptThisSymbols = ["e", "E", "+", "-", "."];


  return (
    <div className="main-wrapper">

      <InstructorHeader activeMenu={"StudentGrid"} />
      {/* Page Wrapper */}
      <div className="page-content p-0">
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-12 p-0'>

              {/* Sidebar */}
              <InstructorSidebar activeMenu={"StudentGrid"} />
              {/* Sidebar */}


              <div className='full-page-content'>
                <div className="title-bar">
                  <div className="page-title-left">
                    <h4 className="page-title">
                      Mijn leerlingen
                    </h4>
                  </div>
                  <div className="page-title-right">

                    {/* SidebarTop */}
                    <SidebarTop />
                    {/* SidebarTop */}

                  </div>

                </div>
                <div className='row'>
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="common-page-padding">
                          <div className="student-list-content">
                            <div className='top-filter d-flex justify-content-between'>
                              <div className='top-filter-left'>
                                <div className='comon-search-group'>
                                  <span className='search-icon'>

                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M7.66536 14.0002C11.1632 14.0002 13.9987 11.1646 13.9987 7.66683C13.9987 4.16903 11.1632 1.3335 7.66536 1.3335C4.16756 1.3335 1.33203 4.16903 1.33203 7.66683C1.33203 11.1646 4.16756 14.0002 7.66536 14.0002Z" stroke="#969BAB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M14.6654 14.6668L13.332 13.3335" stroke="#969BAB" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                  </span>
                                  <input type="text" className="form-control" placeholder="Leerling zoeken" onChange={(e) => handlestudentlist(classID, e.target.value)}></input>

                                </div>
                              </div>
                              <div className='top-filter-right'>
                                <Link to="" onClick={toggle} className="btn btn-primary">Leerling toevoegen
                                </Link>
                                {isOpen &&
                                  <div>
                                    <div className="modal d-block"  >
                                      <div className="modal-dialog">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h1 className="modal-title fs-5" id="exampleModalLabel">Leerling toevoegen</h1>
                                            <button type="button" className="btn-close" onClick={toggle}></button>
                                          </div>
                                          <div className="modal-body">
                                            <form onSubmit={addStudent}>
                                              <div className="form-group text-start">
                                                <label className="form-control-label">Voornaam</label>
                                                <input
                                                  type="text"
                                                  name='firstname'
                                                  className="form-control"

                                                />
                                              </div>
                                              <div className="form-group text-start">
                                                <label className="form-control-label">Achternaam </label>
                                                <input
                                                  type="text"
                                                  name='lastname'
                                                  className="form-control"

                                                />
                                              </div>
                                              <div className="form-group text-start">
                                                <label className="form-control-label">Email</label>
                                                <input
                                                  type="email"
                                                  name='email'
                                                  className="form-control"

                                                />
                                              </div>
                                              <div className="form-group text-start">
                                                <label className="form-control-label">Wachtwoord</label>
                                                <div className="pass-group" id="passwordInputs">
                                                  <input
                                                    type={eye1 ? "password" : "text"}
                                                    name='password'
                                                    className="form-control"

                                                  />
                                                  <span onClick={onEyeClick1} className={`fa toggle-password feather-eye" ${eye1 ? "fa-eye" : "fa-eye-slash"}`} />
                                                  <span className="pass-checked">
                                                    <i className="feather-check" />
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="form-group text-start">
                                                <img className="img-drop-area" height={file ? 100 : 0} width={file ? 100 : 0} src={file} alt='' />
                                                <label className="form-control-label">Foto</label>
                                                <input type="file" className="form-control" name='icon' id="val-number" accept="image/png, image/jpeg" onChange={(e) => onFileChange(e, file)} />
                                              </div>
                                              <div className="form-group text-start">
                                                <label className="form-control-label">Selecteer klas</label>
                                                <br />
                                                <select className="css-zhxdgn-control reportsgamestudent" style={{ width: "100%", borderColor: "#dce0eb" }} onChange={(e) => setClassroomid(e.target.value)} >
                                                  <option className="select" value="">Selecteer klas</option>
                                                  {classdata.map(data => (
                                                    <option key={data.id} className="select" value={data} >{data}</option>
                                                  ))}

                                                </select>

                                              </div>

                                              {/* <div className="form-group text-start">
                                                <label className="form-control-label">Select Current Level</label>
                                                <br />
                                                <select className="css-zhxdgn-control reportsgamestudent" style={{ width: "100%", borderColor: "#dce0eb" }} onChange={(e) => setLevelID(e.target.value)} >
                                                  <option className="select" value="">Select Current Level</option>
                                                  {level.map(data => (
                                                    <option key={data.id} className="select" value={data.id} >{data.title}</option>
                                                  ))}

                                                </select>

                                              </div> */}
                                              {/* 
                                              <div className="form-group text-start">
                                                <label className="form-control-label">Continuity Score</label>
                                                <input type="number" min={0} name='continuityscore' className="form-control" placeholder="Enter Continuity Score" onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={(e) => setContinuityScore(e.target.value)} />
                                              </div> */}


                                              {Error ? <p className=" text-center items-center justify-between rounded bg-warning py-2 text-md text-white">{Error}</p> : ''}
                                              <div className="modal-footer">
                                                <button type="submit" className="btn btn-secondary" >Verzenden</button>
                                                <button type="button" className="btn btn-secondary" onClick={toggle}>Sluit</button>
                                              </div>
                                            </form>
                                          </div>


                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-backdrop fade show"></div>
                                  </div>
                                }
                              </div>
                            </div>
                            {loader ? <Loader /> :
                              <>
                                <div className='stucent-class-select mt-4 mb-4'>
                                  <ul className='comom-lable-list'>
                                    <li key={"all_class"}>
                                      <Link to="" className={(classID == undefined || classID == "" || classID == null) ? "active" : "inactive"} onClick={() => handlestudentlist('', searchName)}> Alle klassen</Link>
                                    </li>
                                    {classdata.map((data) => (
                                      data ?
                                        <li key={"class"}>
                                          <Link to="" className={classID == data ? "active" : "inactive"} onClick={() => handlestudentlist(data, searchName)}>  {data}</Link>
                                        </li>
                                        : ""
                                    ))}
                                  </ul>

                                </div>
                                <div className='studentlist-table'>
                                  <div className='settings-widget'>
                                    <div className=' p-0'>
                                      <div className='comman-space p-0'>
                                        <table className='table table-nowrap mb-0 common-table'>
                                          <thead>
                                            <tr>
                                              <th>Voornaam</th>
                                              <th>Achternaam</th>
                                              <th>E-mailadres</th>
                                              <th>Klas</th>

                                            </tr>
                                          </thead>
                                          {(!noRecord && totalData === 0) &&
                                            <div style={{ marginLeft: "15px", marginTop: "15px", height: "40px" }}>
                                              Data Not Found
                                            </div>}
                                          {noRecord ? (
                                            <div style={{ marginLeft: "15px", marginTop: "15px", height: "40px" }}>
                                              Please wait . . .
                                            </div>
                                          ) : (

                                            <tbody>
                                              {totalData > 0 && studentData.map((data) => (
                                                <tr key={"Studenlist"}>
                                                  <td className='text-bold'>{data.firstname}</td>
                                                  <td>{data.lastname}</td>
                                                  <td>{data.email}</td>
                                                  <td>{data.classroom ? data.classroom : '_'}</td>
                                                  {/* <td>{(data.classroom.classname == undefined || data.classroom.classname == null || data.classroom.classname == "") ? "-" : data.classroom.classname.charAt(0).toUpperCase() + data.classroom.classname.slice(1)}</td> */}
                                                </tr>))}
                                            </tbody>)}
                                        </table>
                                      </div>

                                    </div>
                                  </div>

                                </div>
                              </>

                            }
                          </div>
                        </div>










                        <div className='settings-widget d-none'>
                          <div className='settings-inner-blk p-0'>
                            <div className='sell-course-head student-meet-head comman-space border-0'>
                              <div className='tak-head d-flex align-items-center'>
                                <div>
                                  <h3>Students</h3>
                                  <p>Meet people taking your course.</p>
                                </div>
                                <div className='ms-auto'>
                                  <Link to="" onClick={toggle} className="btn btn-primary">Add Student
                                  </Link>
                                  {isOpen &&
                                    <div>
                                      <div className="modal d-block"  >
                                        <div className="modal-dialog">
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h1 className="modal-title fs-5" id="exampleModalLabel">Add Student</h1>
                                              <button type="button" className="btn-close" onClick={toggle}></button>
                                            </div>
                                            <div className="modal-body">
                                              <form onSubmit={addStudent}>
                                                <div className="form-group text-start">
                                                  <label className="form-control-label">Firstname</label>
                                                  <input
                                                    type="text"
                                                    name='firstname'
                                                    className="form-control"
                                                    placeholder="Enter Firstname"
                                                  />
                                                </div>
                                                <div className="form-group text-start">
                                                  <label className="form-control-label">Lastname </label>
                                                  <input
                                                    type="text"
                                                    name='lastname'
                                                    className="form-control"
                                                    placeholder="Enter Lastname"
                                                  />
                                                </div>
                                                <div className="form-group text-start">
                                                  <label className="form-control-label">Email</label>
                                                  <input
                                                    type="email"
                                                    name='email'
                                                    className="form-control"
                                                    placeholder="Enter Email"
                                                  />
                                                </div>
                                                <div className="form-group text-start">
                                                  <label className="form-control-label">Password</label>
                                                  <div className="pass-group" id="passwordInputs">
                                                    <input
                                                      type={eye1 ? "password" : "text"}
                                                      name='password'
                                                      className="form-control"
                                                      placeholder="Enter Password"
                                                    />
                                                    <span onClick={onEyeClick1} className={`fa toggle-password feather-eye" ${eye1 ? "fa-eye" : "fa-eye-slash"}`} />
                                                    <span className="pass-checked">
                                                      <i className="feather-check" />
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="form-group text-start">
                                                  <img className="img-drop-area" height={file ? 100 : 0} width={file ? 100 : 0} src={file} alt='' />
                                                  <label className="form-control-label">Image</label>
                                                  <input type="file" className="form-control" name='icon' id="val-number" accept="image/png, image/jpeg" onChange={onFileChange} />
                                                </div>
                                                {Error ? <p className=" text-center items-center justify-between rounded bg-warning py-2 text-lg text-white">{Error}</p> : ''}
                                                <div className="modal-footer">
                                                  <button type="submit" className="btn btn-secondary" >Submit</button>
                                                  <button type="button" className="btn btn-secondary" onClick={toggle}>Close</button>
                                                </div>
                                              </form>
                                            </div>


                                          </div>
                                        </div>
                                      </div>
                                      <div className="modal-backdrop fade show"></div>
                                    </div>
                                  }

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      {
                        records.map((element) => {
                          return (

                            <>
                              <div className="col-lg-12 d-flex">
                                <div className="instructor-list flex-fill d-none" onClick={() => navigate(`/student-info/${element.id}`)}>
                                  <div className="instructor-img">
                                    <Link to="#">
                                      <img className="img-fluid" alt="" src={element.image ? imageUrl + "/User/" + element.image : Placeholder} />
                                    </Link>
                                  </div>
                                  <div className="instructor-content">
                                    <h5>
                                      <Link to="#">{element.firstname} {element.lastname}</Link>
                                    </h5>
                                    <h6>
                                      <Link to="#">{element.email}</Link>
                                    </h6>
                                    <h6>Student</h6>


                                  </div>

                                </div>
                              </div>
                            </>
                          )
                        })}

                    </div>

                    <div className="row d-none">
                      <div className="col-md-12">
                        <ul className="pagination lms-page lms-pagination">
                          <li className="page-item prev">
                            <Link
                              className="page-link"

                              onClick={prePage}
                            >
                              <i className="fas fa-angle-left"></i>
                            </Link>
                          </li>
                          {number.map((n, i) => (
                            <li className={`page-item ${currentPage === n ? 'active' : ''} `} key={i}>
                              <Link className="page-link" onClick={() => changeCPage(n)}>
                                {n}
                              </Link>
                            </li>

                          ))}

                          <li className="page-item prev">
                            <Link
                              className="page-link"

                              onClick={nextPage}
                            >
                              <i className="fas fa-angle-right"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* Pagination */}
                  </div>
                  {/* Instructor Dashboard */}

                </div>

              </div>

            </div>







          </div>
        </div>
      </div>
      <Modal show={Success}>
        <Modal.Header>
          <Modal.Title>Save</Modal.Title>
        </Modal.Header>

        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              handlestudentlist()
              setFile("")
              setSuccess(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </div>
  );
}
