import React, { useState, useEffect } from "react";
import { InstructorHeader } from "../../instructor/header";
import InstructorSidebar from "../sidebar";
import SidebarTop from "../sidebartop";
import StudentReporting from "../../../Api/Services/StudentReporting";

import LearningService from "../../../Api/Services/LearningService";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Loader from "../../Loader/Loader";
import TeacherService from "../../../Api/Services/TeacherService";
import ReactApexChart from "react-apexcharts";
import {

  Lock,

} from "react-feather";
import Footer from "../../footer";

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
export const Dashboard = () => {
  const navigate = useNavigate();
  const [subjectData, setSubjectData] = useState([]);
  const [subjectID, setSubjectID] = useState("");
  var imageUrl = process.env.REACT_APP_URL;
  const [isLoading, setIsLoading] = useState(true);
  const [teacherOverAllScore, setTeacherOverAllScore] = useState('');

  const [totalPlayedGames, setTotalPlayedGames] = useState('');
  const [bestClass, setBestClass] = useState('');
  const [worstClass, setWorstClass] = useState('');
  const [allStudent, setAllStudent] = useState('');
  const [worstStudentData, setWorstStudentData] = useState([]);
  const [chartGameName, setChartGameName] = useState([]);
  const [charGametWithClassName, setChartGameWithClassName] = useState([]);
  const customStyles = {
    option: (base, { isFocused }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#ffe9e6" : "#fff",

      };
    }
  };

  const [activeKey, setActiveKey] = useState("Per Domain Per Class");
  const [charGameAverage, setCharGameAverage] = useState([]);
  const [ModalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [charTotalGameCount, setChartTotalGameCount] = useState([]);
  const [playedGameList, setPlayedGameList] = useState([]);
  const [selectedGameValue, setSelectedGameValue] = useState(null)
  // const [classList, setClassList] = useState([]);
  // const [selectedValue, setSelectedValue] = useState(null);
  const [averageTeacherScore, setAverageTeacherScore] = useState('');
  const [averageSchoolScore, setAverageSchoolScore] = useState('');
  const [schoolContinuityScore, setSchoolContinuityScore] = useState('');
  const [teacherContinuityScore, setTeacherContinuityScore] = useState('');
  useEffect(() => {

    if (localStorage.getItem('usertoken')) {
      SubjectList();
      dashboardreport();
      ClassName()

    } else {
      navigate('/login')
    }
  }, [navigate])
  const SubjectList = async () => {
    try {
      let responseData = await StudentReporting.index().json();
      setSubjectData(responseData);
      var getclass = responseData.filter(function (item) {

        return item.id == 1;
      });
      ContinuityPlayedMonthScore(getclass[0].id)
      AvarageScoreStudent(getclass[0].id)
      WorstStudentList(getclass[0].id)
      setSubjectID(getclass[0].id)
      TeacherAverageScore(getclass[0].id)
      SchoolAverageScore(getclass[0].id)
      ContinuitySchoolAverageScore(getclass[0].id)
      ContinuityTeacherAverageScore(getclass[0].id)
      PlayedGameList(getclass[0].id)
      setIsLoading(false)

    } catch (error) {
      console.log(error)
    }
  }

  const dashboardreport = async () => {
    try {


      let responseData1 = await LearningService.all_module_with_task().json()

      console.log(responseData1.overallPercentage, "responseData1.overallPercentage")
      setTeacherOverAllScore(responseData1.overallPercentage);



    } catch (error) {
      console.log(error)
    }

  }
  const ClassName = async () => {
    try {
      let responseData = await TeacherService.student_class_index().json();
      if (responseData.total > 0) {
        // setClassList(responseData.data);

        // setSelectedValue(responseData.data[0]);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handlesubjectlist = async (id) => {
    setIsLoading(true)

    ContinuityPlayedMonthScore(id)
    AvarageScoreStudent(id)
    WorstStudentList(id)
    setSubjectID(id);
    PlayedGameList(id)
    TeacherAverageScore(id)
    SchoolAverageScore(id)
    ContinuitySchoolAverageScore(id)
    ContinuityTeacherAverageScore(id)
    setIsLoading(false)
    if (activeKey == "Per Domain Per Class") {


      return;
    }
    if (activeKey == "Per Class") {

      var userData = {
        "game_id": id,
        // "class_id": 6
      }

      let responseData = await TeacherService.chart_classper_game(userData).json();

      if (responseData.status == true) {
        setChartGameName(responseData.domain_name);
        setCharGameAverage(responseData.game_average);
      } else {
        setModalOpen(true);
        setChartGameName([]);
        setCharGameAverage([]);
        setModalMessage("Data not found");
      }


      return;
    }


  }
  const ContinuityPlayedMonthScore = async (id) => {
    try {
      var userData = {
        "game_id": id,
      }

      let responseData = await TeacherService.continuity_played_month_score(userData).json();

      if (responseData.status == true) {

        setTotalPlayedGames(responseData.total_games_played);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const AvarageScoreStudent = async (id) => {
    try {
      var userData = {
        "game_id": id,
      }

      let responseData = await TeacherService.avarage_score_student(userData).json();

      if (responseData.status == true) {
        console.log(responseData.best_class, "responseData.best_class")
        setBestClass(responseData.best_class);
        setWorstClass(responseData.worst_class);
        setAllStudent(responseData.all_student);
      }
      else {
        setBestClass(0);
        setWorstClass(0);
        setAllStudent(0);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const WorstStudentList = async (id) => {

    try {
      var userData = {
        "game_id": id,
      }

      let responseData = await LearningService.worst_played_student(userData).json();

      if (responseData.status == true) {
        setWorstStudentData(responseData.data)
      }
      else {
        setWorstStudentData([])
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleTabClick = async (key) => {


    if (key === "Per Class") {
      setActiveKey("Per Class");
      var userData = {
        "game_id": subjectID,
        // "class_id": 6
      }

      let responseData = await TeacherService.chart_classper_game(userData).json();

      if (responseData.status == true) {
        setChartGameName(responseData.domain_name);
        setCharGameAverage(responseData.game_average);
      } else {
        setModalOpen(true);
        setChartGameName([]);
        setCharGameAverage([]);
        setModalMessage("Data not found");
      }

    } else {
      setActiveKey("Per Domain Per Class");

      console.log("activeKey")
    }
  };
  const PlayedGameList = async (id) => {

    try {
      var userData = {
        "game_id": id,
        // "domain_id": responseData.data[0].id
      }


      let responseData = await TeacherService.played_game_list(userData).json();


      if (responseData.data.length != 0) {

        var userData1 = {
          "game_id": id,

          "domain_id": responseData.data[0].id,
        }

        let responseData2 = await TeacherService.chart_gamewithclass(userData1).json();

        if (responseData2.status == true) {


          setChartGameWithClassName(responseData2.class_name);

          setChartTotalGameCount(responseData2.total_game_count);
        }
        else {
          setModalOpen(true);
          setModalMessage("Data not found");
        }


        setPlayedGameList(responseData.data);
        setSelectedGameValue(responseData.data[0]);

      }
      else {
        setModalOpen(true);

        setModalMessage("Data not found");
        setChartGameWithClassName([]);

        setChartTotalGameCount([]);
        setPlayedGameList([]);
        setSelectedGameValue();
      }

    } catch (error) {
      console.log(error)
    }
  }
  // const handlePerClass = async (e) => {


  //   setSelectedValue(e);
  //   try {


  //     var userData = {
  //       "game_id": subjectID,
  //       // "class_id": e.id ? e.id : 6
  //     }

  //     let responseData = await TeacherService.chart_classper_game(userData).json();

  //     if (responseData.status == true) {
  //       setChartGameName(responseData.domain_name);
  //       setCharGameAverage(responseData.game_average);
  //     } else {
  //       setModalOpen(true);
  //       setChartGameName([]);
  //       setCharGameAverage([]);

  //       setModalMessage("Data not found");
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }

  // }
  const handleReportGame = async (e) => {


    setSelectedGameValue(e);


    try {

      if (e === "choose") {

        //class name chart
        var chartshow = {
          "game_id": subjectID,

          "domain_id": e.id,
        }

        let responseData2 = await TeacherService.chart_gamewithclass(chartshow).json();

        if (responseData2.status == true) {




          setChartTotalGameCount(responseData2.total_game_count);
        }
        else {
          setModalOpen(true);
          setModalMessage("Data not found");
        }
      }
      else {
        var chartshow1 = {
          "game_id": subjectID,

          "domain_id": e.id,
        }


        let responseData2 = await TeacherService.chart_gamewithclass(chartshow1).json();

        if (responseData2.status == true) {




          setChartTotalGameCount(responseData2.total_game_count);
        }
        else {
          setModalOpen(true);
          setModalMessage("Data not found");
        }
      }




      setIsLoading(false);
    } catch (error) {
      console.log(error)
    }
  }
  const TeacherAverageScore = async (id) => {

    try {

      var userData = {
        "game_id": id,
      }
      let responseData = await TeacherService.school_average_teacher_score(userData).json();

      if (responseData.status == true) {

        setAverageTeacherScore(responseData.data.average_score);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const SchoolAverageScore = async (id) => {
    try {

      var userData = {
        "game_id": id,
      }
      let responseData = await TeacherService.school_average_score(userData).json();

      if (responseData.status == true) {
        setAverageSchoolScore(responseData.data.average_score);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const ContinuitySchoolAverageScore = async (id) => {
    try {

      var userData = {
        "game_id": id,
      }
      let responseData = await TeacherService.school_continuity_score(userData).json();

      if (responseData.status == true) {
        setSchoolContinuityScore(responseData.data.school_continuity_score);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const ContinuityTeacherAverageScore = async (id) => {
    try {

      var userData = {
        "game_id": id,
      }
      let responseData = await TeacherService.continuity_teacher_score(userData).json();

      if (responseData.status == true) {
        setTeacherContinuityScore(responseData.data.continuity_teacher_score);
      }
    } catch (error) {
      console.log(error)
    }
  }
  const max = 10
  const minValue = 0;
  const valueToPercent = (val) => ( (val - minValue) * 100 ) / (max - minValue);

  const seriessemi = [valueToPercent(teacherOverAllScore)]
  
  const optionssemi = {
    colors: ['#4FC971', '#4FC971'],
    chart: {
      type: 'radialBar',
      hight: 350,

      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        min: 0,
        max: 10,
        hollow: {
          size: '55%',
        },
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#F4F5F7",
          strokeWidth: '80%',
          // margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            color: '#999',
            opacity: 0,
            blur: 0,
            strokeWidth: '80%',
          }
        },
        dataLabels: {
          name: {
            show: true
          },
          value: {
            offsetY: -2,
            fontSize: '22px',
            formatter: (val) => ( val * (max - minValue) ) / 100 + minValue,
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: '#CDA7FF',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      },
    },
    labels: ['Gemiddelde Score'],
    stroke: {
      lineCap: 'round'
    },
    
  }

  const optionsradial = {
    stroke: {
      lineCap: 'round'
    },
    colors: ['#CDA7FF', '#CDA7FF'],

    chart: {
      height: 350,
      type: 'radialBar',
      textTransform: "none"
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '55%',
        },
        dataLabels: {
          name: {
            offsetY: 18,

          },
          value: {
            offsetY: -10,
            formatter: function (val) {
              return val + " Gespeeld";
            }
          }
        }
      },
    },
    labels: ['deze maand'],

  }

  const seriesradial = [totalPlayedGames]

  //chart per domain
  const option1s = {
    chart: {
      height: 300,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '12%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#FFA79D"],
    fill: {
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.5,
      },
    },
    xaxis: {
      categories: activeKey == "Per Class" ? chartGameName : charGametWithClassName,
      min: 0, // Minimum value for x-axis
      max: 10, // Maximum value for x-axis
    },

    title: {
      text: undefined,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "12px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: 600,
        cssClass: "apexcharts-xaxis-title",
      },
    },
    yaxis: {
      // seriesName: "seriesY",
      logbase: 10,
      // logarithmic: true,
      tickAmount: 5,
      axisBorder: {
        show: true,
        color: "#FFA79D",
        offsetX: 0,
        offsetY: 0,
      },
    },
    grid: {
      show: true, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: true, //or just here to disable only y axis
        },
        title: {
          text: '$ (thousands)'
        }
      },
    },


  };
  const series = [{
    name: "Score",
    data: activeKey == "Per Class" ? charGameAverage : charTotalGameCount,

  },]
  const AverageOptions = {
    chart: {
      height: 200,
      type: 'bar',
      offsetY: 16,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,

        barHeight: '40%',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, index) {


        return index.dataPointIndex === 0 ? "School : " + val : "Docent : " + val
      },
    },
    xaxis: {
      position: 'bottom',
      min: 0, // Minimum value for x-axis
      max: 10, // Maximum value for x-axis
    },
    yaxis: {
      show: false,
    },
    grid: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    colors: [
      '#4DA0FE',
      '#CDA7FF',

    ],
  };

  const Averageseries = [
    {
      data: [averageSchoolScore, averageTeacherScore],
    },
  ];
  const Continuityseries = [
    {
      data: [schoolContinuityScore, teacherContinuityScore],

    },
  ];

  const ContinuityOptions = {

    chart: {
      height: 200,
      type: 'bar',
      offsetY: 16,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,

        barHeight: '40%',
      },
    },
    dataLabels: {
      enabled: true,
      // formatter: function (val, index) {


      //   return index.dataPointIndex === 0 ? "School : " + val : "Teacher : " + val
      // },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
      },
    },
    xaxis: {
      position: 'bottom',
      categories: ['School', 'Docent'],
      min: 0, // Minimum value for x-axis
      max: 10, // Maximum value for x-axis
    },
    yaxis: {
      show: false,
    },
    grid: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    colors: [
      '#4DA0FE',
      '#CDA7FF',

    ],
  };
  return (



    <div className="main-wrapper">
      {<InstructorHeader activeMenu={"Dashboard"} />}
      <div className="page-content instructor-page-content p-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">

              {/* Sidebar */}
              <InstructorSidebar activeMenu={"Dashboard"} />
              {/* Sidebar */}


              <div className="full-page-content">
                <div className="title-bar">
                  <div className="page-title-left">
                    <h4 className="page-title">
                      Overzicht
                    </h4>
                  </div>
                  <div className="page-title-right">

                    {/* SidebarTop */}
                    <SidebarTop />
                    {/* SidebarTop */}

                  </div>

                </div>
                {isLoading ? <Loader /> :
                  <>
                    <div className="row">
                      {/* Instructor Dashboard */}
                      <div className="col-xl-8 col-lg-12 col-md-12">
                        <div className="dashboard-left">
                          <div className='filter-tabs'>
                            <ul>
                              {subjectData.map((data) => (
                                data.name == "Nulmeting" || data.name == "ontSNAP de wiskunde" ? ""
                                  :
                                  <li key={data.id} onClick={() => setSubjectID(data.id)}>


                                    <Link className={subjectID == data.id ? "active" : "inactive"} to="" onClick={() => handlesubjectlist(data.id)}>
                                      <img className="img-drop-area" height={data.icon ? 15 : 0} width={data.icon ? 15 : 0} src={imageUrl + "/Subject/" + data.icon} alt='' />
                                      &nbsp;
                                      {data.name}
                                    </Link>

                                  </li>
                              ))}
                            </ul>
                          </div>
                          <h5 className="mb-3">Resultaten</h5>
                          <div className="row">
                            <div className="col-xl-4 col-lg-6 d-flex">
                              <div className="card instructor-card w-100 avarage-box box1">
                                <div className="card-body">

                                  <div className="instructor-inner">
                                    <div className="score-box-icon">

                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.55078 9.18329V13.325C3.55078 14.8416 3.55078 14.8416 4.98411 15.8083L8.92578 18.0833C9.51745 18.425 10.4841 18.425 11.0758 18.0833L15.0174 15.8083C16.4508 14.8416 16.4508 14.8416 16.4508 13.325V9.18329C16.4508 7.66662 16.4508 7.66662 15.0174 6.69995L11.0758 4.42495C10.4841 4.08328 9.51745 4.08328 8.92578 4.42495L4.98411 6.69995C3.55078 7.66662 3.55078 7.66662 3.55078 9.18329Z" stroke="#BA84FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14.5827 6.35829V4.16663C14.5827 2.49996 13.7493 1.66663 12.0827 1.66663H7.91602C6.24935 1.66663 5.41602 2.49996 5.41602 4.16663V6.29996" stroke="#BA84FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.5245 9.1583L10.9995 9.89997C11.0745 10.0166 11.2412 10.1333 11.3662 10.1666L12.2162 10.3833C12.7412 10.5166 12.8828 10.9666 12.5412 11.3833L11.9828 12.0583C11.8995 12.1666 11.8328 12.3583 11.8412 12.4916L11.8912 13.3666C11.9245 13.9083 11.5412 14.1833 11.0412 13.9833L10.2245 13.6583C10.0995 13.6083 9.89116 13.6083 9.76616 13.6583L8.94949 13.9833C8.44949 14.1833 8.06616 13.9 8.09949 13.3666L8.14949 12.4916C8.15782 12.3583 8.09116 12.1583 8.00782 12.0583L7.44949 11.3833C7.10782 10.9666 7.24949 10.5166 7.77449 10.3833L8.62449 10.1666C8.75783 10.1333 8.92449 10.0083 8.99116 9.89997L9.46616 9.1583C9.76616 8.7083 10.2328 8.7083 10.5245 9.1583Z" stroke="#BA84FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                      </svg>

                                    </div>
                                    <h6 className="text-transform-none">Hoogste score (gemiddelde)</h6>
                                    <h4 className="instructor-text-dark mt-4 mb-1">{bestClass.score}</h4>
                                    <span className="class-grade">{(bestClass.name == undefined || bestClass.name == null || bestClass.name == "") ? "-" : bestClass.name.charAt(0).toUpperCase() + bestClass.name.slice(1)}</span>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 d-flex px-2">
                              <div className="card instructor-card w-100 avarage-box box2">
                                <div className="card-body">
                                  <div className="instructor-inner">
                                    <div className="score-box-icon">

                                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.29102 1.66663V18.3333" stroke="#F9A978" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4.29102 3.33337H13.6243C15.8743 3.33337 16.3743 4.58337 14.791 6.16671L13.791 7.16671C13.1243 7.83337 13.1243 8.91671 13.791 9.50004L14.791 10.5C16.3743 12.0834 15.791 13.3334 13.6243 13.3334H4.29102" stroke="#F9A978" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                      </svg>

                                    </div>
                                    <h6 className="text-transform-none"> Laagste score (gemiddelde)</h6>
                                    <h4 className="instructor-text-dark mt-4 mb-1">{worstClass.score}</h4>
                                    <span className="class-grade">{(worstClass.name == undefined || worstClass.name == null || worstClass.name == "") ? "-" : worstClass.name.charAt(0).toUpperCase() + worstClass.name.slice(1)}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 d-flex">
                              <div className="card instructor-card w-100 avarage-box box3">
                                <div className="card-body">
                                  <div className="instructor-inner">
                                    <div className="score-box-icon">

                                      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.3238 7.86507V14.135C19.3238 15.1617 18.7738 16.1151 17.8846 16.6376L12.4396 19.7817C11.5505 20.2951 10.4505 20.2951 9.55214 19.7817L4.10714 16.6376C3.21797 16.1242 2.66797 15.1709 2.66797 14.135V7.86507C2.66797 6.83841 3.21797 5.88503 4.10714 5.36253L9.55214 2.21837C10.4413 1.70504 11.5413 1.70504 12.4396 2.21837L17.8846 5.36253C18.7738 5.88503 19.3238 6.82924 19.3238 7.86507Z" stroke="#5199E1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.0011 10.0833C12.1807 10.0833 13.1369 9.12703 13.1369 7.94744C13.1369 6.76785 12.1807 5.81165 11.0011 5.81165C9.82148 5.81165 8.86523 6.76785 8.86523 7.94744C8.86523 9.12703 9.82148 10.0833 11.0011 10.0833Z" stroke="#5199E1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14.6673 15.2716C14.6673 13.6216 13.0265 12.2833 11.0007 12.2833C8.97482 12.2833 7.33398 13.6216 7.33398 15.2716" stroke="#5199E1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                      </svg>

                                    </div>
                                    <h6 className="text-transform-none">Alle leerlingen (gemiddelde)</h6>
                                    <h4 className="instructor-text-dark mt-4 mb-1">{allStudent}</h4>
                                    <span className="class-grade"></span>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="domain-chart-tabs">
                                <div className="select-dropdown common-select">


                                  {
                                    activeKey === "Per Domain Per Class" ?
                                      <Select
                                        className=" select country-select"
                                        name="sellist1"
                                        styles={customStyles}
                                        options={playedGameList}
                                        getOptionValue={(option) => option.name}
                                        getOptionLabel={((option) => option.name ? option.name : option.name)}
                                        defaultValue={playedGameList[0]}
                                        onChange={handleReportGame}
                                        value={selectedGameValue}
                                        isSearchable={false}
                                        placeholder="Selecteer"
                                      ></Select>
                                      :
                                      ""
                                    // <Select
                                    //   className="select country-select"
                                    //   name="sellist2"
                                    //   styles={customStyles}
                                    //   options={classList}
                                    //   getOptionValue={(option) => option.classname}
                                    //   getOptionLabel={((option) => option.classname ? option.classname : option.classname)}
                                    //   defaultValue={classList[0]}
                                    //   onChange={handlePerClass}
                                    //   value={selectedValue}
                                    //   isSearchable={false}
                                    //   placeholder="Selecteer"
                                    // ></Select>

                                  }


                                </div>
                                <div className="mt-2">
                                  <Tabs
                                    defaultActiveKey={activeKey}
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                    onSelect={handleTabClick}
                                  >
                                    <Tab eventKey="Per Domain Per Class" title="Per domein en klas" >
                                      <div className="card instructor-card border-0 mb-0">

                                        <div className="card-body">
                                          <div id="instructor_chart">

                                            {isLoading ? (
                                              <div
                                                style={{
                                                  width: "100px",
                                                  margin: "auto",
                                                }}
                                              >
                                                <p>Please wait</p>
                                              </div>
                                            ) : (
                                              <div>
                                                <ReactApexChart options={option1s} series={series} type="bar"
                                                  height={300} />
                                                {/* <ReactApexChart options={optionsBar} series={seriesBar} type="bar"
                                      height={300} /> */}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                    </Tab>
                                    <Tab eventKey="Per Class" title="Per klas">
                                      <div className="card instructor-card border-0">

                                        <div className="card-body">
                                          <div id="instructor_chart">

                                            {isLoading ? (
                                              <div
                                                style={{
                                                  width: "100px",
                                                  margin: "auto",
                                                }}
                                              >
                                                <p>Please wait</p>
                                              </div>
                                            ) : (
                                              <div>
                                                <ReactApexChart options={option1s} series={series} type="bar"
                                                  height={300} />
                                                {/* <ReactApexChart options={optionsBar} series={seriesBar} type="bar"
                                      height={300} /> */}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Tab>

                                  </Tabs>


                                </div>

                              </div>

                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 d-flex">
                              <div className="card instructor-card w-100 avarage-process">
                                <div className="card-body">
                                  <div className="instructor-inner">
                                    <h6 className="instructor-text-dark mb-1 text-transform-none">Gemiddelde school</h6>
                                    <span className="sub-title">vs gemiddelde mijn leerlingen</span>
                                    <div className="chart">
                                      {/* <ReactApexChart options={optionsBar2} series={seriesBar2} type="bar"
                                    height={300} /> */}

                                      <ReactApexChart
                                        options={AverageOptions}
                                        series={Averageseries}
                                        type="bar"
                                        height={200}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 d-flex">
                              <div className="card instructor-card w-100 avarage-process">
                                <div className="card-body">
                                  <div className="instructor-inner">
                                    <h6 className="instructor-text-dark mb-1">Continuïteitsscore school</h6>
                                    <span className="sub-title">vs Continuïteitsscore mijn leerlingen</span>
                                    <div className="chart">
                                      {/* <ReactApexChart options={optionsBar3} series={seriesBar3} type="bar"
                                    height={300} /> */}
                                      <ReactApexChart
                                        options={ContinuityOptions}
                                        series={Continuityseries}
                                        type="bar"
                                        height={200}
                                      />
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-12 col-md-12">
                        <div className="dashboard-right">
                          <h5 className="mb-3">Continuïteitsscore</h5>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <div className="card border-0 w-100 chart-border">
                                <div className="card-body p-0">
                                  <div className="instructor-inner border-0">
                                    <div className="circle-chart">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="chart-1">
                                            {/* <ReactApexChart options={optionsBar3} series={seriesBar3} type="radialBar"
                                    height={150} /> */}
                                            <ReactApexChart options={optionssemi} series={seriessemi} type="radialBar" height={245} width={245} />
                                          </div>

                                        </div>
                                        <div className="col-md-6">
                                          <div className="chart-2">
                                            {/* <ReactApexChart options={optionsBar4} series={seriesBar4} type="radialBar"
                                    height={150} /> */}
                                            <ReactApexChart options={optionsradial} series={seriesradial} type="radialBar" height={245} width={245} />
                                          </div>

                                        </div>

                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                          <h5 className="mb-4">10 leerlingen met de laagste scores</h5>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <div className="card instructor-card w-100  border-0">
                                <div className="card-body p-0 ">
                                  <div className="instructor-inner">
                                    {worstStudentData.map((data) => (
                                      <div key={"worststudent"} className="worst-row">
                                        <div className="worst-left">
                                          <h6>{data.student_name}</h6>
                                          <span>{data.domain_name}</span>
                                        </div>
                                        <div className="worst-right">
                                          <Lock size={15} />    {data.average_score}
                                        </div>
                                      </div>
                                    ))}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </>
                }

              </div></div></div></div></div>
      <Footer />

      <Modal className='selectgame' show={ModalOpen} onHide={() => setModalOpen(false)}>
        <p className='selectgametext' style={{ textAlign: 'center', marginTop: '10%' }}>    {modalMessage}</p>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setModalOpen(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  )
}